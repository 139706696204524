import React from 'react';
import { makeStyles } from '@material-ui/core';

import Page from '../../../components/Page';
import Section from '../../../components/Section';
import Typography from '../../../components/Typography';

const useStyles = makeStyles(theme => ({
    bullet: {
      '&:before': {
        content: '"\u2022"'
      }
    }
  }));

const BurnoutMindingYourMind = () => {

    const classes = useStyles();

    return (
        <Page title='Burnout'>
            <Section theme='primary'>
                <Typography type='hero' preset={1}>Burnout</Typography>
                <Typography type='paragraph' preset={1}>In here: Emotional health, Daily activity</Typography>
                <Typography type='paragraph' preset={1}>Jan 2022 <span className={ classes.bullet } /> 40 min</Typography>
            </Section>
            <Section>
                <Typography type='heading' preset={1}>Episode Description</Typography>
                <Typography type='paragraph' preset={1}>What is 'burnout'? A temporary loss of motivation and energy at work that can be fixed by a week at the beach? Or an indicator of mental exhaustion and a sign a person might be psychologically unwell? If you experience 'burnout', what should you do?</Typography>
            
                <iframe
                    title='Burnout'
                    src='https://open.spotify.com/embed-podcast/episode/1YvyBlfPT6ck0EEihj5GWc?si=08bbd6d739df49f5'
                    width='100%'
                    height='212'
                    frameBorder='0'
                    allowtransparency="true"
                    allow="encrypted-media"
                />
            </Section>
        </Page>
    )
};

export default BurnoutMindingYourMind;